var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-6" },
    [
      _c(
        "div",
        { staticClass: "text-center", attrs: { width: "100%" } },
        [
          _c("v-icon", { attrs: { "x-large": "" } }, [
            _vm._v(_vm._s(_vm.icon)),
          ]),
          _c("div", { staticClass: "my-2" }, [
            _vm._v(" " + _vm._s(_vm.subTitle) + " "),
          ]),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }